<template>
  <div class="storageSample">
    <h1>仓储取样</h1>
    <el-form :inline="true">
      <el-form-item label="取样日期">
        <el-date-picker v-model="searchParam.sampleDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="sampleDate" label="取样日期" width="120" show-overflow-tooltip />
      <el-table-column prop="truckNumber" label="车号" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="customNote" label="销售品名" width="120" show-overflow-tooltip />
      <el-table-column prop="storageSampleMixDetailList" label="配比" show-overflow-tooltip>
        <template #default="scope">
          <span v-for="(item, index) in scope.row.storageSampleMixDetailList" :key="item.product">
            <el-text style="margin: 0 10px;" v-if="index != 0">:</el-text>
            <el-text>{{ item.product + '[' + item.mixNumber + ']' }}</el-text>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="storageDiweifareliangKc" label="仓储化验·大卡" width="120" show-overflow-tooltip />
      <el-table-column prop="customDiweifareliangKc" label="客户化验·大卡" width="120" show-overflow-tooltip />
      <el-table-column prop="" label="差值" width="120" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ math.plus(scope.row.customDiweifareliangKc, -scope.row.storageDiweifareliangKc) }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'


const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.sampleDates = []
  searchParam.custom = null
  searchParam.storage = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'storageSample')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'storageSample', null)
}


const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.sampleDates && searchParam.sampleDates.length == 2) {
    params.sampleDateGe = util.parseTime(searchParam.sampleDates[0], '{y}-{m}-{d}')
    params.sampleDateLe = util.parseTime(searchParam.sampleDates[1], '{y}-{m}-{d}')
  }
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  api.get('/backend/storageSample/page', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'storageSample', searchParam)
  cache.setObject(cache.keys.pageParam + 'storageSampleDetail', null)
  router.push('/storageSampleDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'storageSample', searchParam)
  const storageSample = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'storageSampleDetail', storageSample)
  router.push('/storageSampleDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(storageSample => storageSample.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/storageSample/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}
</script>

<style lang="less"></style>